body {
  //fleet css
  .camo-fleet-module{
		overflow-x: auto;
			.MuiTableCell-stickyHeader {
				background:#b5bedb;
			}
			.MuiTable-root .MuiTableCell-root{
				border:1px solid #e0e0e0;
				text-align: center;
			}
      .fleet-ad-header{
        .ad-list-style{
          min-width: 180px !important;
        }
        .sb-des-list-style{
          min-width: 230px !important;
        }
      }
        .MuiTable-root .MuiTableCell-root{
          padding: 8px 6px !important;
      }
     
   }
   //report css
   .engine-off-wing{
		overflow-x: auto;
			.MuiTableCell-stickyHeader {
				background:#b5bedb;
			}
			.MuiTable-root .MuiTableCell-root{
				border:1px solid #e0e0e0;
				text-align: center;
			}
   }
  .engine-workscope-module-table {
    .border-right {
      border-right: 1px solid #dee2e6;
    }

    .border-left {
      border-left: 1px solid #dee2e6;
    }

    .workscope-table-heading {
      background: #f0f2f3;
      border: 1px solid #dfe3e6;
      margin: 0;
      overflow: visible;

      h6 {
        padding: 6px 6px;
        @include elementStyles($size: 12px,
          $color: #6d9bb9,
          $fontFamily: $ibmplexMedium );
      }

      .workscope-level {
        h6 {
          border-bottom: 1px solid #dfe3e6;
          text-align: center;
        }
      }
    }

    .engine-workscope-table {
      background: #fff;

      .add-new-row {
        @include elementStyles($size: $font13,
          $fontFamily: $ibmplexMedium,
          $color: #3f51b5);
        border: 1px solid #3f51b5;
        padding: 3px 6px;
        display: inline-block;
        border-radius: 3px;
        cursor: pointer;
      }

      .workscope-row {
        border-bottom: 1px solid #d7d7d7;

        .module-col {
          position: relative;

          .module-content {
            padding: 15px;
          }
        }

        .workscope-sub-row {
          border-bottom: 1px solid #d7d7d7;

          &:last-child {
            border-bottom: none;
          }

          .submodule-col {
            .submodule-content {
              padding: 15px;
            }
          }

          .workscope-item-row {
            border-bottom: 1px solid #d7d7d7;

            .item-col {
              .item-content {
                width: 100%;
                padding: 15px;
              }

              &.workscope-level {}
            }
          }
        }
      }
    }
  }

  .maintenance-claims {
    table {
      tr {
        cursor: pointer;
      }
    }
  }

  .maintenance-module-section {
    margin-top: 60px;

    .maintenance-heads {
      margin-bottom: 15px;

      h3 {
        @include elementStyles($size: $font20,
          $fontFamily: $ibmplexRegular,
          $color: #bd10e0,
          $margin: 0 0 5px 0);
      }

      h4 {
        @include elementStyles($size: $font16,
          $fontFamily: $ibmplexRegular,
          $color: #bd10e0,
          $margin: 0 0 5px 0);
      }

      p {
        @include elementStyles($size: $font14,
          $fontFamily: $ibmplexRegular,
          $color: rgba(0, 0, 0, 0.54));
      }
    }

    .no-upcoming-events-card {
      background: $whiteColor;

      .upcoming-events-card-body {
        padding: 40px;

        h4 {
          @include elementStyles($size: $font18,
            $fontFamily: $ibmplexLight,
            $color: #ffae42,
            $margin: 0 0 20px 0);
        }

        ul {
          li {
            @include elementStyles($size: $font14,
              $fontFamily: $ibmplexRegular,
              $color: #000000,
              $margin: 0 0 6px 0);

            &::before {
              content: "- ";
            }
          }
        }
      }
    }

    .assembly-claim-list {
      margin-bottom: 20px;

      h4 {
        display: inline-block;
        @include elementStyles($size: $font20,
          $fontFamily: $ibmplexLight,
          $color: #000000);
      }

      button {
        margin-left: auto;
      }
    }

    .assembly-desc {
      font-size: 14px;
      color: #7a7a7a;
      position: absolute;
    }

    .title-info {
      margin-bottom: 20px;

      .arrow-icon {
        float: left;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      h4 {
        display: inline-block;
        font-size: 22px;
        font-family: $ibmplexLight !important;
        font-weight: 200;
      }

      p {
        font-size: 14px;
        color: #bd10e0;
        font-family: $ibmplexRegular;
      }
    }

    .mr-claim {
      font-family: $ibmplexRegular;
      font-size: 20px;
      font-weight: 200;
    }

    .shop-visit-list {

      // margin-top: 20px;
      .add-sv-cta {
        margin-bottom: 20px;
        float: right;
      }

      table {
        tbody {
          tr {
            td {
              .status {
                font-size: 13px;
                padding: 4px 6px;
                border-radius: 4px;
                text-align: center;
                width: 90px;

                &.draft {
                  background-color: #d2f0d8;
                }

                &.in-review {
                  border: 1px solid #d19d01;
                  background-color: #ffbf00;
                }

                &.approved {
                  border: 1px solid #5cb603;
                  background-color: #83ea1f;
                }

                &.rejected {
                  border: 1px solid #b52607;
                  background-color: #f44a25;
                  color: $whiteColor;
                }

                &.submitted {
                  border: 1px solid #0d28bf;
                  background-color: #3f51b5;
                  color: $whiteColor;
                }

                .workscope-label {
                  @include elementStyles($size: 13px,
                    $fontFamily: $ibmplexRegular,
                    $color: #877201);
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }

      .workscope-wrapper {
        position: relative;
        background: #f5f7fa;
        border: 1px solid #c6cddd;
        border-radius: 3px;

        .run-type,
        .total-cost {
          background: #f0f2f3;
          padding: 15px;
          border-bottom: 1px solid #d9d9d9;

          h4 {
            @include elementStyles($size: 16px,
              $fontFamily: $ibmplexRegular,
              $color: #bd10e0);
            margin-bottom: 10px;
          }
        }

        .workscopes-block,
        .performance-block,
        .llp-block {
          background: #fff;
          padding: 15px;
          border-bottom: 1px solid #d9d9d9;

          h4 {
            @include elementStyles($size: 16px,
              $fontFamily: $ibmplexRegular,
              $color: #bd10e0);
            margin-bottom: 10px;
          }
        }
      }
    }

    .add-mr-claim {
      margin-bottom: 20px;
      float: right;
    }

    .add-engine-llp {
      margin-bottom: 20px;
      float: right;
    }

    .mnt-event-card {
      margin-bottom: 40px;
      position: relative;

      .edit-sv {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        color: #2f3f9f;
        cursor: pointer;
      }

      .content-info {
        padding: 25px 15px 15px 15px;

        &.payout-claim-info {
          background: #f7f3dd;
          border-top: 1px solid #ddd4a6;
          position: relative;
        }

        table {
          background: #faf4e7;
          padding: 5px;
          border-radius: 4px;

          tr {
            td {
              &:first-child {
                @include elementStyles($size: $font12,
                  $fontFamily: $ibmplexRegular,
                  $color: #212529);
              }

              padding: 3px;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .upcoming-shop-visit {
      span {
        @include elementStyles($size: $font16,
          $fontFamily: $ibmplexRegular,
          $color: #9439e4);
        display: inline-block;
        margin-bottom: 8px;
      }

      .shopvisit-row {
        margin-bottom: 30px;
        padding: 10px;
        background: $whiteColor;

        label {
          @include elementStyles($size: $font12,
            $fontFamily: $ibmplexRegular,
            $color: #dfd700);
        }

        h4 {
          @include elementStyles($size: $font16,
            $fontFamily: $ibmplexRegular,
            $color: #000000);
        }

        h6 {
          @include elementStyles($size: $font14,
            $fontFamily: $ibmplexRegular,
            $color: #000000);
        }

        p {
          @include elementStyles($size: $font13,
            $fontFamily: $ibmplexRegular,
            $color: #7a7a7a);
          margin-bottom: 6px;
        }
      }
    }

    .no-records-found {
      text-align: center;
      background-color: #f5f5fd;
      position: relative;
      border: 1px dashed #c4c4c4;
      border-radius: 4px;
      min-height: 400px;

      .inner-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .claim-text {
          @include elementStyles($size: $font16,
            $fontFamily: $ibmplexMedium,
            $color: #000000);
          margin-bottom: 10px;
        }

        .add-mr-claim {
          float: none;
        }
      }
    }
  }

  .status {
    font-size: 13px;
    padding: 8px 6px;
    border-radius: 4px;
    text-align: center;
    width: 90px;

    .status-btn {
      font-size: 13px;
      padding: 4px 6px;
      border-radius: 4px;
      text-align: center;
      margin-top: 3px;
      width: 100px;

      &.draft {
        background-color: #d2f0d8;
      }

      &.in-review {
        border: 1px solid #d19d01;
        background-color: #ffbf00;
      }

      &.approved {
        border: 1px solid #5cb603;
        background-color: #83ea1f;
      }

      &.rejected {
        margin-top: 4px !important;
        border: 1px solid #b52607;
        background-color: #f44a25;
        color: $whiteColor;
      }

      &.submitted {
        border: 1px solid #0d28bf;
        background-color: #3f51b5;
        color: $whiteColor;
      }
    }

    .workscope-label {
      @include elementStyles($size: 12px,
        $fontFamily: $ibmplexRegular,
        $color: #877201);
      margin-top: 2px;
    }
  }

  .asset-listing-section {
    .asset-list-heading {
      color: #bd10e0;
      font-family: $ibmplexRegular;
      margin-bottom: 4px;
      font-size: 16px;
    }

    .asset-list-description {
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      margin-bottom: 5px;
    }

    .add-asset-cta {
      float: right;

      .MuiPaper-root {
        // transform: translate(10px, 0px) !important;
      }
    }

    .status {
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 4px;
      text-align: center;
      width: 90px;
      white-space: normal;

      &.on-lease {
        // background: #beed87;
        background-image: linear-gradient(90deg, #beed87, #d2e9b6 95%);
        color: #5ec264;
      }

      &.off-lease {
        // background: #ffb98d;
        background-image: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
        color: #ee7c26;
      }

      &.on-ground {
        // background: #729cf1;
        background-image: linear-gradient(269deg, #a7c4ff, #729cf1);
        color: #4c79d5;
      }

      &.sold {
        background: linear-gradient(to bottom,
            #d7d7d7 0%,
            #f6f6f6 95%,
            white 100%);
        color: #000000;
      }
    }

    .asset-export {
      // display: flex;
      // justify-content: right;
      // text-align: right;
      // margin-bottom: 5px;

      .MuiButton-root {
        margin-right: 10px;
        padding: 5px 10px;
        min-width: 90px;
        font-size: 11px;
        font-weight: 600;
      }

      .MuiSvgIcon-root {
        fill: currentColor;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 20px;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
      }

      .MuiButtonBase-root {
        border: 1px solid rgba(63, 81, 181, 0.5);
      }

      .MuiButton-textPrimary {
        padding: 0;
      }

      .MuiButton-label {
        color: #3f51b5;
      }

      .MuiButton-text {
        padding: 5px 15px;
      }
    }

    ul {
      margin-top: 3px;

      li {
        &.portfolio-type {
          background-color: #56b4da;
          color: $whiteColor;
          border-radius: 4px;
          padding: 2px 10px;
          margin-right: 3px;
          margin-left: 5px;
        }

        svg {
          font-size: 18px;
          vertical-align: middle;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        // white-space: nowrap;

        span {
          white-space: nowrap;
        }
      }

      .action-icon {
        padding: 0;
      }
    }

    .MuiTabs-scroller {
      .MuiTab-wrapper {
        font-size: 13px;
      }
    }
  }

  .MuiDialog-paper {
    .MuiButton-root {
      padding: 6px 10px;
    }

    .data-upload-modal {
      .data-upload-instructions {
        margin-bottom: 15px;

        .info-area {
          padding: 10px;
          border-radius: 3px;
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
        }

        .alert-msg {
          @include elementStyles($size: 13px,
            $fontFamily: $ibmplexMedium,
            $color: #000000);
        }
      }

      .dropzone-area {
        width: 500px;
        position: relative;
        margin: 0 auto;

        .drag-drop-cnt {
          .MuiDropzoneArea-textContainer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .MuiDropzoneArea-text {
              margin-top: auto;
            }

            .MuiDropzoneArea-icon {
              color: #3f51b5;
            }
          }
        }
      }
    }
  }
}

#assetHeader {
  .header-wrapper {
    margin-top: 10px;
    border-bottom: 1px solid #d2cdcd;
    padding-bottom: 20px;

    .nav-wrapper {
      width: 100%;

      .nav-item {
        text-align: center;

        .nav-title {
          @include elementStyles($size: $font12,
            $lineHeight: 16px,
            $color: $blackColor );
          margin-bottom: 7px;
        }

        .nav-info {
          @include elementStyles($size: $font14,
            $lineHeight: 18px,
            $weight: 600,
            $color: $primaryColor );
        }

        .btn-primary {
          border-radius: 0;
          font-size: $font14;
          padding: 6px 10px;
        }
      }
    }

    .MuiButton-root {
      margin-right: 50px;

      .MuiButton-label {
        white-space: nowrap;
      }

      .MuiButton-endIcon {
        margin-left: 70px;
      }
    }
  }
}

#AmpSidebar {
  position: sticky;
  top: 80px;

  .sidebar-list {
    box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2);

    li {
      @include elementStyles($size: $font14,
        $lineHeight: 18px,
        $color: $blackColor,
        $padding: 15px);
      cursor: pointer;

      &.active,
      &:hover {
        color: $whiteColor;
        background-color: $primaryColor;
      }
    }
  }
}

#AmpTableHeader {
  margin-top: 5px;

  .MuiAccordionSummary-content {
    justify-content: space-between;
  }

  .MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    margin-top: 3px;
    border-collapse: collapse;
  }

  .MuiAccordionSummary-root {
    display: flex;
    padding: 0px 16px;
    min-height: 40px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    margin-right: 5px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
  }

  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .header-icons {
    box-shadow: 0px 4px 12px 0.7px rgb(0 0 0 / 15%);
    height: 30px;
    width: 30px;
    padding: 5px;
    margin: 5px;

    &.refresh-icon {
      background-color: rgb(242, 248, 255);
      color: rgb(17, 155, 214);
    }

    &.list-outline-icon {
      background-color: rgb(235, 253, 239);
      color: rgb(120, 229, 143);
    }

    &.piechart-icon {
      background-color: rgb(254, 247, 236);
      color: rgb(237, 193, 130);
    }

    &.edit-icon {
      background-color: rgb(255, 255, 255);
      color: rgb(43, 76, 167);
    }
  }

  .table-wrapper {
    background-color: #efefef;

    .font-b {
      font-weight: bold;
    }

    .table-head {

      // margin-top: 5px;
      .MuiTableCell-head {
        padding-top: 12px;
      }
    }

    // box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2);
    .header-title {
      background-color: $primaryColor;
      color: $whiteColor;
      font-weight: 600;
    }

    .MuiTableCell-root {
      border-right: 0.5px solid #ebedf2;
      padding: 0px 16px;
      border-bottom: none;
    }
  }

  // .left-panel {
  //   padding-right: 15px;
  // }
  .MuiTableCell-body {
    &:nth-child(3) {
      white-space: nowrap;
    }
  }

  .MuiAccordion-root {
    box-shadow: none;
    border: 1px solid #dedfe3;
    border-radius: none;

    .MuiTableRow-root {
      background-color: transparent !important;
    }

    .MuiAccordionSummary-root {
      border-bottom: 1px solid #dedfe3;
      border-radius: 0px;
    }

    .MuiAccordionDetails-root {
      padding: 0;
    }

    .MuiTypography-root {
      font-size: $font14;
      line-height: 18px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 10px 0;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      height: 45px;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;
    }
  }
}

.amp-listing-section {
  .pr-0 {
    padding-right: 0 !important;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .p-5px {
    padding: 5px;
  }

  .p-5px {
    padding: 5px;
  }

  .mr-18 {
    margin-right: 18px;
  }

  .module-heading {
    color: #bd10e0;
    font-family: $ibmplexRegular;
    // margin-bottom: 4px;
    font-size: 16px;
  }

  .custom-border {
    border: 1px solid #dedfe3;
    margin-top: 12px;
  }

  margin-right: 15px;

  .MuiBox-root-2 {
    display: flex;
    place-items: center;
  }

  .MuiBox-root-9 {
    display: flex;
    place-items: center;
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

#AmpSearchBar {
  margin: 10px 15px;

  .menu-btn {
    padding: 0;
    margin-right: 10px;
    min-width: auto;
    display: none;

    .MuiButton-label {
      background-color: #a9c4ea;
      height: 39px;
      width: 41px;
      border-radius: 3px;

      .MuiSvgIcon-root {
        fill: $whiteColor;
        font-size: 34px;
      }
    }
  }

  .btn-light-green {
    background-color: #79e7904a !important;

    &.MuiButton-contained {
      box-shadow: none;
    }
  }

  .btn-light-purple {
    background-color: #ca91fc59 !important;
  }

  .import-task-icon {
    color: $primaryColor;

    // padding-right: 5px;
    &.rotate {
      transform: rotateY(180deg);
      padding-right: 0;
      padding-left: 5px;
    }
  }

  .border-right {
    border-right: 1px solid #d2cdcd;
    margin-right: 20px;
    height: 45px;
  }

  @media (max-width: 1224px) {
    .search-input {
      width: 300px !important;
    }
  }

  .search-input {
    position: relative;
    width: 425px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #dfe3e6;

    input {
      border: none !important;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInput-input {
      // box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2);
      border: 1px solid #dfe3e6;
      height: 30px;
      border-radius: 3px;
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::placeholder {
        font-size: $font12;
        max-width: 360px;
        min-width: 360px;
        width: 360px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .MuiInput-underline {
      &::before {
        border: none;
      }
    }

    .search-icon {
      // position: absolute;
      // right: 2px;
      // top: 10px;
      padding: 0 10px;
      background-color: $whiteColor;
    }

    .MuiInput-underline {
      &::after {
        border-bottom: none !important;
      }
    }
  }

  .btn-wrapper {
    .MuiButton-root {
      margin-right: 10px;
      padding: 5px 10px;
      min-width: 90px;
      font-size: 11px;
      font-weight: 600;
    }

    .MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 20px;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
  }

  .btn-text {
    margin-right: 10px;
    padding: 6px 8px;
    border-radius: 4px;

    .MuiIconButton-label {
      font-size: $font14;
      line-height: 18px;
      color: $blackColor;
    }
  }
}

.upload-input {
  display: none;
}

.import-bg {
  background-color: rgba(121, 231, 144, 0.2901960784);
}

.export-bg {
  background-color: rgba(202, 145, 252, 0.3490196078);
}

#AmpTable {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-x: auto;
  max-height: 488px;
  overflow-y: auto;

  #amp {
    .MuiTableCell-root {

      &:nth-child(5),
      &:nth-child(21) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }

  #ht {
    .MuiTableCell-root {

      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(21) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }

  #ad {
    .MuiTableCell-root {
      &:nth-child(5) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }
  #smbc_ad {
    .MuiTableCell-root {
      &:nth-child(6) {
        white-space: normal;
        min-width: 350px !important;
      }
    }
  }
  #llp {
    .MuiTableCell-root {
      &:nth-child(3) {
        white-space: normal;
        min-width: 250px !important;
      }
    }
  }

  #sb {
    .MuiTableCell-root {
      &:nth-child(6) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }
  #smbc_sb {
    .MuiTableCell-root {
      &:nth-child(4) {
        white-space: normal;
        min-width: 300px !important;
      }
    }
}

  #oc {
    .MuiTableCell-root {

      &:nth-child(3),
      &:nth-child(12) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }

  #mods {
    .MuiTableCell-root {
      &:nth-child(6) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }

  #sr {
    .MuiTableCell-root {

      &:nth-child(5),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(17) {
        white-space: normal;
        min-width: 400px !important;
      }
    }
  }


  #inventory {
    .MuiTableCell-root {
      &:nth-child(2),&:nth-child(3) {
        white-space: normal;
        min-width: 250px !important;
      }
      &:nth-child(4) {
        white-space: normal;
        // min-width: 25px !important;
      }
      &:nth-child(10) {
        white-space: normal;
        min-width: 25px !important;
      }
    }
  }
  #softTime{
    .MuiTableCell-root {
      &:nth-child(4){
        white-space: normal;
        min-width: 250px !important;
      }
      }
    }
    
  .attach-icon {
    transform: rotate(45deg);
    padding-right: 3px;
  }

  .action-icon {
    cursor: pointer;
  }

  .MuiTableCell-body {
    // border: 1px solid #dedfe3;
    border-right: 1px solid #dedfe3;
    border-top: 1px solid #dedfe3;
    border-bottom: none;
  }

  .MuiTableCell-root {
    // border-right: 0.5px solid #ebedf2;
    // border-bottom: 0.5px solid #ebedf2;
    // white-space: nowrap;
    padding: 5px 5px;
    text-align: center;

    &:first-child {
      // min-width: 60px !important;

      .edit-icon,
      .delete-icon {
        padding: 2px 0px 2px 0px !important;
      }
    }

    &.MuiTableCell-head {
      white-space: nowrap;
      // border: 1px solid #dedfe3;
      border-right: 1px solid #dedfe3;
      border-top: 1px solid #dedfe3;
      border-bottom: none;
    }

    .MuiButtonBase-root {
      white-space: nowrap;
      padding: 2px 5px 2px 5px;
    }
  }
  .ml-10 {
    margin-left: 10px;
  }

  .MuiTableHead-root {
    // background-color: #a6b9d5;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;

    span,
    .MuiTableCell-head {
      // border-bottom: none !important;
      font-size: 13px;
      font-weight: 600;
      color: #6d9bb9;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }

  .delete-icon {
    .MuiSvgIcon-root {
      fill: #f50057;
    }
  }

  .edit-icon {
    .MuiSvgIcon-root {
      fill: $primaryColor;
    }
  }
}

.MuiTable-root {
  .MuiTableCell-root {
    padding: 10px 16px;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      &:nth-child(odd) {
        background-color: #ebeff6;
      }

      &:nth-child(even) {
        background-color: #f5f7fa;
      }
    }
  }
}

#ht-dropdown {

  .MuiGrid-root,
  .MuiFormControl-root {
    width: 100%;
  }
}

#HtTaskForm,
#adTaskForm {
  #data-container {
    &.MuiGrid-container {
      // align-items: flex-end;
    }

    #dimensions-input {
      margin-bottom: 8px;
      margin-top: 20px;
    }
    #dimensions-input-sr {
      margin-bottom: 8px;
      margin-top: 30px;
    }

    #dimensions-input-ht {
      margin-bottom: 8px;
      margin-top: 23px;
    }

    #lastdone-input {
      margin-top: 30px;
    }

    .mb-10 {
      display: inline-block;
      margin-bottom: 10px;
    }

    .repair-period {
      margin-bottom: 8px;
      margin-top: 30px;
    }
  }

  #d-block {
    display: block;
  }

  .remaining-input {
    min-width: 100%;
    padding: 8px;
  }

  .upload-textbox {
    .MuiButton-label {
      padding: 7px 10px !important;
    }
  }

  .dropdown-label {
    padding-left: 10px;
    // top: -3px;
  }
}

#AmpTaskForm {
  input[type="text"]:disabled{
    background: #f9f9f9;
  }
  .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    /* flex-direction: column; */
    vertical-align: top;
  }

  .MuiGrid-container .MuiTextField-root {
    width: 100%;
    // height: 50px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px;
  }

  .just-center {
    align-items: center;
  }

  .font-14 {
    font-size: $font14;
  }

  .MuiOutlinedInput-input {
    padding: 15px 10px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 0px 14px;
  }

  .ml-10px {
    margin-left: 8px;
    margin-bottom: 5px;
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 10px;
    color: $whiteColor;
    cursor: pointer;
  }

  .MuiDialogContent-root:first-child {
    // padding-top: 20px;
  }

  .MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 30px 24px;
    // overflow-y: auto;
    // overflow-y: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
  }

  .MuiGrid-spacing-xs-2 {
    width: 100%;
    // margin: -8px;
  }

  .MuiGrid-container {
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    .MuiTextField-root {
      width: 100%;
    }
  }

  #data-container {
    .MuiGrid-container {
      margin: 0;
    }

    .MuiGrid-root {
      label {
        display: block;
      }
    }
  }

  .border-grey {
    border-bottom: 1px solid #7d7d7d;
    margin: 0 0px;
    width: 100%;
  }

  .MuiDialogActions-root {
    justify-content: flex-start;

    .MuiButton-root {
      width: 160px;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .upload-amp-textbox{
    ul.spacing-list{
      padding: 30px 0px 30px 0px;
      li{
          margin-right: 10px;
          span{
              @include elementStyles($size: 12px, $color: #3f51b5, $fontFamily: $ibmplexRegular);
              cursor: pointer;
          }
      }
  }
  }
  .checklist-files{
    ul{
        li{
            border: 1px solid #d7d7d7;
            padding: 3px 40px 3px 8px;
            border-radius: 20px;
            position: relative;
            display: inline-block;
            margin: 2px;
            .file-remove{
                position: absolute;
                right: 5px;
                top: 20%;
                cursor: pointer;
                svg{
                    font-size: 15px;
                    cursor: pointer;
                }
            }
            span.file-name{
                @include elementStyles($size: 11px, $color: #000000, $fontFamily: $ibmplexRegular);
                cursor: pointer;
            }
        }
    }
}

  .MuiGrid-root {
    .MuiFormLabel-root {
      font-size: $font13;
      // line-height: 18px;
    }

    .upload-textbox {
      height: 100%;

      .MuiButton-root {
        background-color: #fff;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.23);
        width: 100%;
        height: 100%;

        .MuiButton-label {
          display: block;
        }
      }

      #upload-success {
        padding: 10px;
        border: 2px dashed $primaryColor;
        margin: 0 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      #success-text {
        text-align: right;
        margin: 5px 30px 0 0;

        .upload-text {
          color: #008000;
        }
      }

      .MuiSvgIcon-root {
        fill: $primaryColor;
        padding-right: 5px;
      }

      .upload-input {
        display: none;
      }

      .upload-text {
        color: rgb(102, 102, 102);
        font-size: $font12;
        line-height: 14px;

        &.upload {
          text-decoration: underline;
          padding-right: 5px;
        }

        &.success {
          color: #008000;
        }

        &.overflow {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
    }
  }

  .MuiDialogTitle-root {
    background-color: $primaryColor;
    color: $whiteColor;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 5px 24px;
  }

  .MuiDialogActions-root {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background-color: $whiteColor;
    border-top: 1px solid #ebebeb;
  }

  .show-attachments {
    // margin: 15px 10px 0px 0px;
    // padding-bottom: 10px;
    flex-wrap: wrap;

    .file-icon {
      height: 30px;
      width: 30px;
      padding-right: 15px;
    }

    .cancel-icon {
      cursor: pointer;
      position: absolute;
      fill: #ff0000;
      right: 10px;
      top: -7px;
      height: 15px;
      width: 15px;
    }

    .file-name {
      font-size: $font12;
      line-height: 16px;
    }
  }

  .actions-btn {
    .MuiButton-root {
      margin-right: 0px;
      padding: 4px 10px;
      min-width: 90px;
      font-size: 12px;
      font-weight: 600;

      .MuiButton-label {
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 20px;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
  }

  .mods {
    .mod-period {
      margin-bottom: 8px;
      // margin-top: 8px;

      .ml-10px {
        margin-bottom: 12px;
      }
    }

    #custom-padding {
      .ml-10px {
        margin-bottom: 4px;
      }
    }
  }
}
.checklist-file-linking{
  h4{
      svg{
          margin-right: 4px;
      }
      span{
          @include elementStyles($size: $font13, $color: black, $fontFamily: $ibmplexRegular, $weight:400);
          cursor: pointer;
      }
  }
  .selected-files{
      background: #f0f0f0;
      h3{
          @include elementStyles($size: $font14, $color: black, $fontFamily: $ibmplexRegular, $weight:400);
          padding: 8px 10px;
      }
      .selected-files-blk{
          padding: 8px 10px;
          .file-list{
              display: flex;
              padding: 4px;
              border-bottom: 1px solid #d7d7d7;
              p{
                  @include elementStyles($size: 9px, $color: black, $fontFamily: $ibmplexRegular, $weight:400);
              }
          }
          span.remove-file{
              margin-left: auto;
          }
      }
  }
  .file-location{
      @include elementStyles($size: 9px, $color: black, $fontFamily: $ibmplexRegular, $weight:400);
      margin-left: 24px;
  }
  .PrivateSwitchBase-root-1{
      padding: 0 !important;
      float: right;
  }
  .linking-navigation{
      background: #f1f1f1;
      padding: 4px;
      margin-bottom: 10px;
  }
}

.red-row {

  // background-color: #f5ada4 !important;
  .MuiTableCell-body {
    // color: $redColor;
    // border: 1px solid #dedfe3;
  }
}

.Mui-error {
  span {
    font-size: $font12;
  }
}

.export-list {
  padding: 0 !important;

  .MuiSvgIcon-root {
    padding-right: 5px;
  }

  .MuiButton-root {
    color: #2b4ca7;
  }
}

.import-menu-item {
  .MuiMenu-list {
    padding: 0;
  }

  .MuiListItem-root {
    padding: 0;
  }

  .upload-input {
    display: none;
  }

  .btn-text {
    padding: 6px 6px;

    &.btn-light-green {
      background-color: rgba(121, 231, 144, 0.2901960784) !important;
    }
  }

  .import-task-icon {
    fill: #2b4ca7;

    &.rotate {
      transform: rotateY(180deg);
      padding-right: 0;
      padding-left: 5px;
    }

    &.download {
      transform: rotate(90deg);
      padding-right: 0 !important;
      margin-right: 5px;
    }
  }

  &.export {
    .btn-light-purple {
      background-color: rgba(202, 145, 252, 0.3490196078) !important;
      border-radius: 0;
    }

    .MuiIconButton-label {
      font-size: $font14;
      line-height: 18px;
      color: $blackColor;
    }

    .MuiSvgIcon-root {
      padding-right: 5px;
    }
  }
}

#table-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.MuiButton-textPrimary {
  color: $blackColor !important;
}

.resp-main {
  margin: "84px 0px 0px -10px";
}

// .MuiMenuItem-root {
//   padding-top: 1px !important;
//   padding-bottom: 1px !important;
//   font-size: 15px !important;
// }

.MuiList-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

// #HtTaskForm #data-container #dimensions-input, #adTaskForm #data-container #dimensions-input {
//   margin-bottom: -15px;
// }

#demo-simple-select-label {
  // padding: 0px 14px !important;
  padding: 0px 22px 0 10px !important;
  margin: -7px 0 0 8px;
  background-color: $whiteColor;
  z-index: 1;
  font-size: $font12;
}

.attachment-margin {
  margin-top: 10px;
}

// #AmpTable {
//   .MuiTableCell-root {
//     padding: 5px !important;
//     text-align: center;
//     white-space: normal;
//     &.MuiTableCell-head {
//       white-space: nowrap;
//     }
//   }
// }

// .MuiTable-root .MuiTableCell-root {
//   min-width: 100px !important;
//   max-width: 200px !important;
//   overflow: hidden !important;
//   text-overflow: ellipsis !important;
//   white-space: nowrap !important;
// }
.llpheaderStyle{
  .MuiTable-root .MuiTableCell-root {
    max-width: none !important;
  }
}
.sv-action{
  min-width: 200px !important;
}
.sv-reset-tslsv-cslsv{
  .MuiIconButton-label{
    margin: 4px !important;
    padding: 2px;
  }
}

.MuiTable-root .MuiTableCell-root {
  min-width: 100px ;
  max-width: 200px !important;
  word-wrap: break-word !important;
  padding-left: 10px !important;
}

.getAttachmentDialogue {
  max-width: 1200px;
  width: 480px;

  .MuiDialogTitle-root {
    padding: 5px 20px;
  }

  .MuiBox-root {
    top: -3px;
  }

  .MuiButton-root {
    width: 115px;
    padding: 6px 10px;

    .MuiButton-label {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
    }
  }
}

#AmpTable .MuiTableHead-root span,
#AmpTable .MuiTableHead-root .MuiTableCell-head {
  text-align: center !important;
}

.downloadIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.showAttachmentList {
  max-height: 400px;
}

.MuiFormLabel-root {
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}
.MuiInputBase-input.Mui-disabled {
  background: #f6f6f6;
  cursor:not-allowed;
}

.ConfirmDialog {
  .MuiBox-root {
    top: -4px;
  }

  .MuiDialogTitle-root {
    padding: 5px 20px;
  }

  .MuiTypography-h6 {
    font-size: 18px;
  }

  .MuiButton-label {
    font-size: $font12;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.MuiDialog-paperWidthLg {
  width: 100%;
}

.user-profile-blk .user-profile .user-detail {
  padding: 25px 0;
  margin: 0 0 15px;
}

.label {
  font-size: 13px;
  color: #a0a0a0;
}

.MuiTable-root {
  border-collapse: unset !important;
}

#analytics {
  .MuiButton-root {
    min-width: 225px;
  }

  .claims-side-card {
    margin-top: 30px;
  }

  #analytics-dropdown {
    padding: 10px 32px 10px 10px;
    font-size: $font14;
  }
}

#analytics-datatable {
  .font-600 {
    font-weight: 600;
  }

  .no-records {
    background-color: $whiteColor;
  }

  .header {
    color: #bd10e0;
    background-color: #f5f7fa;
    display: block;
    padding: 5px 5px;
    font-size: 18px;
    font-family: "Conv_IBMPlexSans-Regular";
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .table-border {
    border: 1px solid #e0e0e07d;
    padding: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }
}

#menu-list-grow {
  &.MuiList-padding {
    padding: 0;

    .MuiListItem-root {
      padding: 5px 10px !important;
      font-size: $font14;
    }
  }
}

.custom-width {
  width: 610px;

  .MuiSvgIcon-root {
    cursor: pointer;
  }
}

#add-aircraft {
  .MuiSvgIcon-fontSizeSmall {
    font-size: $font14;
  }

  .plt-filter-body {
    .MuiInputBase-input {
      font-size: $font14 !important;
    }
  }

  .MuiFormLabel-root {
    font-size: 13px;
  }

  .MuiInputBase-input {
    &::placeholder {
      font-size: 12px;
    }
  }

  #date_of_manufacture {
    .MuiSvgIcon-root {
      height: 16px;
      width: 16px;
    }
  }

  #form-label {
    .MuiFormControlLabel-label {
      font-size: $font14;
    }
  }

  .plt-filter-body {
    width: 600px !important;
  }
}

#edit-basic-detail {
  justify-content: flex-end;

  .edit-asset-icon {
    color: rgb(43, 76, 167) !important;
    margin: 5px 22px 0px 0 !important;
  }
}

.profile-img {
  height: 130px;
  width: 130px;
}

.profilepic-container {
  margin-left: 30px;
}

.suggestion-item {
  padding: 7px 10px;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 7px 10px;
  background-color: #eef2f9;
  cursor: pointer;
}


#BasicDetailForm {
  .plt-filter-body {
    width: 100%;
  }
}
.custom-alerts-modal{
   .MuiDialog-paper {
    min-width: 550px;
  }
}
.deleted-search-box{
  .MuiOutlinedInput-root{
    height: 45px;
  }
}
.cal-table-row{
  padding: 4px;
  color:rgba(0, 0, 0, 0.87);
  background-color: #f6f8ff;
  font-size:  15px;
  &:nth-child(even){
      background: #fff;
    }
    &:hover{
        background-color: #e7ebf9;
  }
}
.fleet-due{
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height:0px;
  }
}
.dashboard-dialogue{
  .MuiDialogContent-root{
    overflow-y: hidden;
  }
}
.on-Off{
  .MuiDialog-paperWidthSm{
   max-width: 1250px
 }
}
.dashboard-chart{
  border: 1px solid #d7d7d7;
  .dashboard-chart-title{
    padding:10px;
    border-bottom: 1px solid #d7d7d7;
    background: #B5BEDB;
    h5{
      @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $weight:600); 
    }
  }
}
.autocomplete-dropdown-container {
  >div {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
  }
}
