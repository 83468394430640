@import 'variables';
body {
  // .MuiTabs-scroller {
  //   // border-bottom: 1px solid #d7d7d7;
  //   // margin-bottom: 20px;
  // }

  .MuiTabs-scrollable {
    margin-bottom: 20px !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.9);
  }

  .MuiTooltip-tooltip {
    font-size: 12px;
  }

  .MuiButton-containedPrimary,
  .MuiButton-outlinedPrimary {
    font-size: 13px;
  }

  .MuiButton-root {
    text-transform: none;
  }

  @media only screen and (max-width: 667px) {
    .MuiDialog-paper {
      margin: 8px !important;
    }

    .MuiTabs-scrollButtons {
      width: 20px !important;
    }

    .MuiDialog-paperWidthSm {
      min-height: 300px !important;
    }
  }

  @media only screen and (orientation: landscape) {
    .MuiDialog-paper {
      margin: 8px !important;
      min-height: 320px !important;
    }
  }
  #basic-menu {
    .MuiMenu-paper {
      transform: translate3d(0px, 36px, 0px) !important;
    }
  }

  #basic-dropdown {
    .MuiPaper-root {
      box-shadow: none !important;
      border: 1px solid #dfe3e6;
    }
    .MuiMenu-paper {
      // top: 57px !important;
      transform: translate3d(0px, 36px, 0px) !important;
      width: 160px;

      .MuiList-root {
        padding: 0 !important;
      }
      .MuiButtonBase-root {
        border-bottom: 1px solid #dfe3e6;
        font-size: $font14;
        color: $blackColor;
        &:hover {
          background-color: $primaryColor;
          color: $whiteColor;
          p {
            color: $whiteColor;
          }
        }
        p {
          font-size: $font14;
          color: $blackColor;
        }
      }
    }
    &.analytics-menu {
      .MuiMenu-paper {
        width: 225px;
      }
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: 1200px;
    width: 100%;

    .MuiDialogTitle-root {
      background-color: $primaryColor;
      color: $whiteColor;
    }

    &.MuiPickersModal-dialogRoot {
      width: auto;
    }
  }

  .input-calender {
    &#disabled {
      .MuiInputAdornment-root {
        display: none;
      }

      .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
      }
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 3px;
    }

    .MuiOutlinedInput-inputAdornedEnd {
      padding-left: 10px;
    }

    .MuiInputBase-input {
      font-size: $font12;
      height: 20px;
    }

    .MuiIconButton-root {
      padding: 5px;

      .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
      }
    }
  }

  .disabled-input {
    .MuiInputBase-input {
      background-color: #d1d1d18a;
      cursor: not-allowed;
    }
  }

  #htDialog {
    .MuiDialog-paper {
      min-height: auto !important;

      .MuiDialogContent-root {
        flex: none;
      }
    }

    .MuiDialog-paperWidthSm {
      max-width: 1200px;
      width: auto;
    }
  }
  .utilization-addBlock-modal{
    .MuiDialog-paperWidthSm {
      width: 370px!important;
      min-height: 125px !important;
    }
    // .MuiDialog-paper .MuiButton-root {
    //   padding: 0px !important;
    // }
  }
  .delete-record-modal{
    .MuiDialog-paperWidthSm {
      width: auto;
      min-height: 250px !important;
    }
    text-align: center;
    padding: 30px;
    h4{
        @include elementStyles($size: 18px, $color: #000000,);
    }
    .actions-cta{
      margin-top: 40px;
        button{
            margin-left: 10px;
            &.first-child{
            margin-left: 0;
            }
        }
    }
  }
}
.engine-tech-spec{
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 4px;
}
}

.MuiTabs-indicator {
  width: 115px ;
}

.MuiTableSortLabel-icon {
  opacity: 0.5 !important;
}
.MuiFormLabel-asterisk {
  color: $redColor;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 115px !important;
  }
}
.mui-table-format{
  &.hovered-table{
    tr{
      background: #E6EBFB;
      cursor: pointer;
    }
  }
  .actions-cell{
    text-align: right;
    svg{
      cursor: pointer;
    }
  }
  thead{
    background: #B5BEDB;
    th{
      padding: 5px 10px;
      background: #B5BEDB;
      @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
      span{
        @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
        &.MuiTableSortLabel-root{
          &:hover{
            color: #000;
            .MuiTableSortLabel-icon{
              color: #000 !important;
            }
          }
          .MuiTableSortLabel-icon{
            opacity: 0.5 !important;
          }
        }
        &.MuiTableSortLabel-active{
          color: #496ac5;
          .MuiTableSortLabel-icon{
            color: #496ac5 !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  tbody{
    tr{
      &:hover{
        background: #eff0f5 !important;
      }
      &:nth-child(odd){
        background: #fff;
      }
      &:nth-child(even){
        background: #E6EBFB;
      }
      &.Mui-selected{
        background: rgba(245, 0, 87, 0.08);
      }
      td{
        word-break: break-word;
        padding: 5px 10px;
        @include elementStyles($size: 14px, $color:#000, $fontFamily: $ibmplexRegular, $weight:400);
      }
    }
  }
}